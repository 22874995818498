import React, { useState, useEffect, Suspense } from 'react'
import { Amplify, Auth } from 'aws-amplify'
import { Switch, Route } from 'react-router-dom'
import AuthRouter from '../Global/AuthRouter'
import { ApiProvider } from '../Contexts/ApiContext'
import { Routes, RouteList } from '../Global/Routes'
import ScrollToTop from '../Global/ScrollToTop'
import Analytics, {
  analyticsReset,
  trackUserSignedIn,
} from '../Global/Analytics'
import awsConfig from '../aws-exports'
import IdleTimer from '../Components/auth/IdleTimerContainer'
import TimeoutModal from '../Components/auth/TimeoutModal'
import { SiteUnderMaintenancePage } from '../Components/SiteUnderMaintenance'
import UnsupportedBrowserPage from '../Components/UnsuportedBrowserPage'
import { datadogRum } from '@datadog/browser-rum'
import { Authenticator } from '@aws-amplify/ui-react'
import { useLocation } from 'react-router-dom'
import LoadingPage from '../Components/LoadingPage'
import NYDCA from '../Components/NYDCA'
import ForeclosureAvoidance from '../Components/ForeclosureAvoidance'
import HomeToolkit from '../Components/HomeToolkit/HomeToolkit'
import CommonApiProvider from '../Contexts/CommonApiContext'
import { useAuth0 } from '@auth0/auth0-react'
import { clearLocalCache } from '../services/cache'

const ErrorBoundary = React.lazy(() =>
  import('../Containers/ErrorBoundary'),
)
const BadRequestPage = React.lazy(() =>
  import('../Components/BadRequestPage'),
)
const Layout = React.lazy(() => import('../Global/Layout'))
const LayoutModern = React.lazy(() =>
  import('../Global/LayoutModern'),
)
const CommonQuestions = React.lazy(() =>
  import('../Components/CommonQuestions'),
)
const CommonForms = React.lazy(() =>
  import('../Containers/CommonFormsContainer'),
)
const Contact = React.lazy(() => import('../Components/Contact'))
const PrivacyPolicy = React.lazy(() =>
  import('../Components/PrivacyPolicy'),
)
const TermsConditions = React.lazy(() =>
  import('../Components/TermsAndConditions'),
)
const Configs = React.lazy(() => import('../Containers/Configs'))
const AccessibilityStatement = React.lazy(() =>
  import('../Components/AccessibilityStatement'),
)
const ScheduleOfFees = React.lazy(() =>
  import('../Components/ScheduleOfFees'),
)
const Licenses = React.lazy(() => import('../Components/Licenses'))
const ImportantDisclosures = React.lazy(() =>
  import('../Components/ImportantDisclosures'),
)
const Claim = React.lazy(() => import('../Components/Claim'))
const HelpfulResources = React.lazy(() =>
  import('../Components/HelpfulResources'),
)
const Payment = React.lazy(() => import('../Components/Payment'))

Amplify.configure(awsConfig)

const getResetTimerState = () => ({ isTimedOut: false, timer: null })

const getTimeUntilLogoutAlert = () =>
  process.env.REACT_APP_TIME_UNTIL_LOGOUT_ALERT || 840

const getTimeUntilLogoutAfterAlert = () =>
  process.env.REACT_APP_TIME_UNTIL_LOGOUT_AFTER_ALERT || 60

// Hub.listen('auth', (data) => {
//   if (data.payload.event === 'signOut') {
//     // Cache.clear()
//     clearLocalCache()
//     localStorage.removeItem('selectedTab')
//   }
// })

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthenticated1, setIsAuthenticated1] = useState(false)
  const {
    isAuthenticated: auth0Authenticated,
    isLoading,
    loginWithRedirect,
    user: authUser,
    logout: authLogout,
  } = useAuth0()
  const [userInfo, setUserInfo] = useState({})
  const [timer, setTimer] = useState(getResetTimerState())
  const [lastActive, setLastActive] = useState(null)
  const { pathname } = useLocation()
  let userTraits = localStorage.getItem('ajs_user_traits')
  // const shouldLayoutRender = !userTraits || isAuthenticated
  const modernRoutes = [
    '/',
    '/home-toolkit',
    '/common-questions',
    '/common-forms',
    '/helpful-resources',
    '/contact',
    '/payment',
  ]
  const IS_REGISTRATION_PAGE = pathname === '/register'

  useEffect(() => {
    if (!isLoading) {
      if (auth0Authenticated) {
        // setIsAuthenticated1(auth0Authenticated)
        if (!!authUser) {
          setIsAuthenticated1(auth0Authenticated)
          const signinSegment = localStorage.getItem('signSegment')
          if (!signinSegment) {
            localStorage.setItem('signSegment', 'true')
            setTimeout(() => {
              console.log('track user signed in')
              trackUserSignedIn()
            }, 2000)
          }
        } else {
          setIsAuthenticated1(false)
          authLogout()
        }
      } else {
        setIsAuthenticated1(false)
        clearLocalCache()
      }
    }
  }, [auth0Authenticated, isLoading, authUser])

  const updateAuthenticatedStatus = async () => {
    // const userTraits = localStorage.getItem('ajs_user_traits');
    if (!userTraits) return

    const user = await Auth.currentUserInfo()
    const userID = !!user && user.id
    const isUserSignedIn = !!userID
    if (!isUserSignedIn) {
      localStorage.removeItem('ajs_user_traits')
    }
    setUserInfo(user)
    setIsAuthenticated(!!user)
  }

  const handleLogout = async () => {
    setLastActive(null)
    setTimer(getResetTimerState())
    authLogout()
  }
  // useEffect(()=>{
  //   authLogout()
  // },[])
  useEffect(() => {
    const checkAuthStatus = async () => {
      updateAuthenticatedStatus()
    }
    checkAuthStatus()
  }, [])

  useEffect(() => {
    if (lastActive && isAuthenticated1 && timer.isTimedOut) {
      if (
        (new Date().getTime() - lastActive.getTime()) / 1000 >=
        parseInt(getTimeUntilLogoutAlert()) +
          parseInt(getTimeUntilLogoutAfterAlert())
      )
        handleLogout()
    }
  }, [lastActive, isAuthenticated1, timer.isTimedOut])

  useEffect(() => {
    // Run! Like go get some data from an API.
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
      site: process.env.REACT_APP_DATADOG_RUM_SERVICE,
      beforeSend: (event) => {
        if (event.error && event.error.message.includes('segment')) {
          return false
        }
      },
    })
  }, [])
  if (isLoading) {
    return <LoadingPage />
  }
  console.log(userInfo, 'userinfo')
  console.log(authUser, 'authUser')
  // console.log("adding console change for re-deploying in dev environment")
  return (
    <div className="App">
      {/* {!isAuthenticated1 ? <button style={{marginTop:'100px'}} onClick={()=>{loginWithRedirect()}}>Login Here</button>:<button onClick={()=>{authLogout()}}>Auth Logout</button>} */}
      {isAuthenticated1 ? (
        <>
          <IdleTimer
            timeInSeconds={getTimeUntilLogoutAlert()}
            onTimeout={(timer) => {
              setTimer({ timer, isTimedOut: true })
              console.log('timed out')

              // Idle timer keeps calling onTimeout as you are idle. We want to record the first time the user is idle and then reset the flag by setting it to null
              if (!lastActive)
                setLastActive(new Date(timer.getLastActiveTime()))
            }}
            onLogout={handleLogout}
          />
          {timer.isTimedOut ? (
            <TimeoutModal
              showModal={timer.isTimedOut}
              handleClose={() => {
                timer.timer.reset()
                setTimer({ ...timer, isTimedOut: false })
                setLastActive(null)
              }}
              handleLogout={() => {
                handleLogout()
              }}
              timeoutInSeconds={getTimeUntilLogoutAfterAlert()}
              lastActive={lastActive}
            />
          ) : (
            <div />
          )}
        </>
      ) : (
        <div />
      )}
      {/* <BrowserRouter> */}
      <UnsupportedBrowserPage>
        <ApiProvider isAuthenticated={isAuthenticated1}>
          <ScrollToTop />
          <Analytics
            userInfo={authUser}
            isAuthenticated={isAuthenticated1}
          />
          <Authenticator.Provider>
            <Suspense
              fallback={
                <div>
                  {' '}
                  <LoadingPage isHomePage={pathname === '/'} />{' '}
                </div>
              }
            >
              {modernRoutes.includes(window.location.pathname) ? (
                // <Suspense fallback={<div> <LoadingPage isHomePage={pathname==='/'} /> </div>}>
                <CommonApiProvider isAuthenticated={isAuthenticated1}>
                  {!isLoading && (
                    <LayoutModern isAuthenticated={isAuthenticated1}>
                      {/* <Suspense fallback={<div> <LoadingPage isHomePage={pathname==='/'} /> </div>}> */}
                      <Switch>
                        <Route
                          key="/helpful-resources"
                          exact
                          path="/helpful-resources"
                          component={HelpfulResources}
                        />
                        <Route
                          key="/home-toolkit"
                          exact
                          path="/home-toolkit"
                          component={HomeToolkit}
                        />
                        <Route
                          key="/common-questions"
                          exact
                          path="/common-questions"
                          component={CommonQuestions}
                        />
                        <Route
                          key="/common-forms"
                          exact
                          path="/common-forms"
                          component={CommonForms}
                        />{' '}
                        <Route
                          key="/contact"
                          exact
                          path="/contact"
                          component={Contact}
                        />
                        {RouteList.map(({ path }) => path).some(
                          (path) =>
                            path
                              .toLowerCase()
                              .includes(pathname.toLowerCase()),
                        ) ? (
                          <ErrorBoundary>
                            {isAuthenticated1 ? (
                              <SiteUnderMaintenancePage>
                                <AuthRouter
                                  isAuthenticated={isAuthenticated1}
                                  setUserInfo={setUserInfo}
                                  setIsAuthenticated={
                                    setIsAuthenticated
                                  }
                                  authUser={authUser}
                                >
                                  <Routes />
                                </AuthRouter>
                              </SiteUnderMaintenancePage>
                            ) : (
                              <AuthRouter
                                isAuthenticated={isAuthenticated1}
                                setUserInfo={setUserInfo}
                                setIsAuthenticated={
                                  setIsAuthenticated
                                }
                              >
                                <Routes />
                              </AuthRouter>
                            )}
                          </ErrorBoundary>
                        ) : (
                          <BadRequestPage />
                        )}
                      </Switch>
                      {/* </Suspense> */}
                    </LayoutModern>
                  )}
                </CommonApiProvider>
              ) : (
                // </Suspense>
                // <Suspense fallback={<div> <LoadingPage /> </div>}>
                <CommonApiProvider isAuthenticated={isAuthenticated1}>
                  {!isLoading && (
                    <Layout isAuthenticated={isAuthenticated1}>
                      {/* {shouldLayoutRender &&  <Layout isAuthenticated={isAuthenticated}> */}
                      {/* <Suspense fallback={<div style={{marginTop:'10rem'}}> Loading2222 </div>}> */}
                      <Switch>
                        <Route
                          key="/privacy-policy"
                          exact
                          path="/privacy-policy"
                          component={PrivacyPolicy}
                        />
                        <Route
                          key="/terms-conditions"
                          exact
                          path="/terms-conditions"
                          component={TermsConditions}
                        />
                        <Route
                          key="/schedule-of-borrower-fees"
                          exact
                          path="/schedule-of-borrower-fees"
                          component={ScheduleOfFees}
                        />
                        <Route
                          key="/licenses"
                          exact
                          path="/licenses"
                          component={Licenses}
                        />
                        <Route
                          key="/important-disclosures"
                          exact
                          path="/important-disclosures"
                          component={ImportantDisclosures}
                        />
                        <Route
                          key="/claims"
                          exact
                          path="/claims"
                          component={Claim}
                        />
                        <Route
                          key="/accessibility-statement"
                          exact
                          path="/accessibility-statement"
                          component={AccessibilityStatement}
                        />
                        <Route
                          key="/nydca"
                          exact
                          path="/nydca"
                          component={NYDCA}
                        />
                        <Route
                          key="/foreclosure-avoidance"
                          exact
                          path="/foreclosure-avoidance"
                          component={ForeclosureAvoidance}
                        />
                        {RouteList.map(({ path }) => path).some(
                          (path) =>
                            path
                              .toLowerCase()
                              .includes(pathname.toLowerCase()),
                        ) ? (
                          <ErrorBoundary>
                            {isAuthenticated1 ||
                            IS_REGISTRATION_PAGE ? (
                              <SiteUnderMaintenancePage>
                                <AuthRouter
                                  isAuthenticated={isAuthenticated1}
                                  setUserInfo={setUserInfo}
                                  setIsAuthenticated={
                                    setIsAuthenticated
                                  }
                                  authUser={authUser}
                                >
                                  <Routes />
                                </AuthRouter>
                              </SiteUnderMaintenancePage>
                            ) : (
                              <AuthRouter
                                isAuthenticated={isAuthenticated1}
                                setUserInfo={setUserInfo}
                                setIsAuthenticated={
                                  setIsAuthenticated
                                }
                              >
                                <Routes />
                              </AuthRouter>
                            )}
                          </ErrorBoundary>
                        ) : (
                          <BadRequestPage />
                        )}
                      </Switch>
                      {/* </Suspense> */}
                    </Layout>
                  )}
                </CommonApiProvider>
                // </Suspense>
              )}
            </Suspense>
          </Authenticator.Provider>
        </ApiProvider>
      </UnsupportedBrowserPage>
    </div>
  )
}

export default App
