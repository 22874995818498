import { useState, useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

export const useLoggedInUser = (dependency = null) => {
  const [data, setData] = useState({
    data: null,
    error: null,
    loading: true,
  })
  const { user:authUser } = useAuth0()

  useEffect(() => {
    const saturateData = async () => {
      try {
        if (authUser)
          setData({
            data: authUser,
            loading: false,
            error: false,
          })
        else throw new Error('user does not exist')
      } catch (ex) {
        setData({ error: ex })
      }
    }
    saturateData()
  }, [dependency])

  return data
}
