// import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
// import 'core-js/es/map'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import App from './Containers/App'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import ApolloWrapper from './AuthApolloProvider'

let AppWithLaunchDarkly = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY,
  user: {
    key: 'anonymous-user',  // Use the consistent anonymous key
    anonymous: true,  // Mark as anonymous
  },
})(App)
const root = createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <Auth0Provider 
      clientId='Wx7ml2UcPGq1i83qpaq628P7Jh0boP0Q'
      domain = "reversedepartment.us.auth0.com"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      cacheLocation="localstorage"
  >
    <ApolloWrapper>
      <AppWithLaunchDarkly />
      </ApolloWrapper>
    </Auth0Provider>
 </BrowserRouter>
)