import { useRetrieveLocEligibility } from "../Hooks/useRetrieveLocEligibility";
import { useRetrievePayOffEligibility } from "../Hooks/useRetrievePayOffEligibility";
import { useLocation } from "react-router-dom";
import { useState,useEffect,useRef } from "react";
import { MyLoanContext } from "./MyLoanContext";
import { retrieveLocEligibility, retrievePayOffEligibility } from "../services/api";
import { useApolloClient } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";

export default function CommonApiProvider({isAuthenticated,children}){
     
    return (
        isAuthenticated?(
            <>
                <AuthCommonApiProvider>
                    {children}
                </AuthCommonApiProvider>
            </>
        ):
        <>{children}</>
    )
}
export const AuthCommonApiProvider=({children})=>{
    const [locStatus, setLocStatus] = useState({ loading: true })
    const [payOffEligibility, setPayOffEligibility] = useState({
        loading: true,
      })
    const isMounted = useRef(false)
    const location = useLocation()
    const { 
        error: eligibilityError,
        loading: eligibilityLoading,
        data: eligibilityData,
      }=locStatus
    
    const { 
        error: payoffError,
        loading: payoffLoading,
        data: payoffData,
      } = payOffEligibility;
      const client = useApolloClient()
      const {user:authUser} = useAuth0()
      useEffect(() => {
        let intervalId1
        let intervalId2

        const isApiCallAllowed = !isMounted.current || location.pathname==="/my-loan"
        if (isApiCallAllowed){
            if(!isMounted.current){
                isMounted.current = true
            }
            intervalId1 = setInterval(async () => {
                const cachedLoanId = sessionStorage.getItem('loanId')
          
                if (cachedLoanId) {
                  clearInterval(intervalId1); // Stop checking
                  let locStatus = null
                  let error = null
                  try {
                    if (cachedLoanId) {
                      const loanNumber = JSON.parse(cachedLoanId)
                      locStatus = await retrieveLocEligibility(loanNumber,client)
                    }
                  } catch (err) {
                    error = err
                  } finally {
                    if (error)
                      setLocStatus({ data: { data: { getLOCEligibility: { info: null } } }, error, loading: false })
                    else
                      setLocStatus({ data: locStatus, error, loading: false })

                  }
                }
              }, 500); // Check every second (500 milliseconds)


               // retreieve payoff setInterval
         intervalId2 = setInterval(async ()=>{
            const user = localStorage.getItem('ajs_user_traits');
            if(!user){
                clearInterval(intervalId2);
                return
            };
      
            const personId = await authUser['custom:person_id']
            const cachedLoan = localStorage.getItem('GET_LOAN_KEY')
            if (cachedLoan && cachedLoan !== 'undefined') {
              clearInterval(intervalId2);
              const loan = JSON.parse(cachedLoan).data.loan.loanData
              let payoffData = null
              let error = null
              try {      
                const loanNumber = loan.loanId
                const state = loan.property.state
                payoffData = await retrievePayOffEligibility(loanNumber,client)
                if (state === 'OR' || state === 'NV') {
                  payoffData.data.getPayoffEligibility.info.eligible = false
                }
              } catch (err) {
                console.log('err', err)
                error = err
              } finally {
                setPayOffEligibility({
                  data: payoffData && payoffData.data.getPayoffEligibility.info || null,
                  error,
                  loading: false,
                })
              }
            }
      
          },500)
        }
    
       
        // Clean up the interval when the component unmounts
        return () => {
          if (isApiCallAllowed){
            clearInterval(intervalId1);
          clearInterval(intervalId2);
          }
        };
      }, [location.pathname])

      return (
        <MyLoanContext.Provider value={{
            eligibilityData,
            eligibilityLoading,
            eligibilityError,
            payoffData,
            payoffLoading,
            payoffError,
            setLocStatus,
            setPayOffEligibility
        }}>
            {children}
        </MyLoanContext.Provider>
    )
}
