import { Auth } from 'aws-amplify'
import axios from 'axios'

import awsConfig from '../aws-exports'
import { clearLocalCache } from './cache'

// We can remove this function 
// export const refreshUser = async () => {
//   await Auth.currentAuthenticatedUser({ bypassCache: true })
// }

export const getRandomLetters = (length) => {
  var alphabet = 'abcdefghijklmnopqrstuvwxyz'
  return [...Array(length)]
    .map((x) =>
      alphabet.charAt(Math.floor(Math.random() * alphabet.length)),
    )
    .join('')
}



// export const updateUserAttribute = async (attribute, value) => {
//   const user = await Auth.currentAuthenticatedUser()

//   const result = await Auth.updateUserAttributes(user, {
//     [attribute]: value,
//   })
//   console.log('result', result)

//   return result
// }

// export const checkIsUserSignedIn = async () => {
//   const signedInUser = await Auth.currentUserInfo()
//   return !!signedInUser && signedInUser.id
// }

// export const logOut = async () => {
//   try {
//     await Auth.signOut()
//     clearLocalCache()
//   } catch (error) {
//     console.warn('error logging out:', error)
//   }
//   console.warn('signed out')
//   window.location = '/'
// }

export const turnOnMfaForCurrentUser = async (authUser) => {
  console.log('user to be MFAed')
  const preferredMFA = await Auth.getPreferredMFA(authUser, {
    bypassCache: true,
  })
  console.log('preferred MFA', preferredMFA)
  if (preferredMFA !== 'SMS_MFA') {
    console.log('Setting MFA as required')
    //https://github.com/aws-amplify/amplify-js/issues/932

    await rememberDevice(
      authUser,
      (result) => console.log('call result: ' + result),
      (err) => alert(err),
    )

    await Auth.setPreferredMFA(authUser, 'SMS')
    // refreshUser()
  }
}

// export const turnOffMfaForCurrentUser = async () => {
//   const user = await getUser()
//   await Auth.setPreferredMFA(user, 'NOMFA')
// }

export const rememberDevice = async (user, onSuccess, onFailure) => {
  await user.setDeviceStatusRemembered({
    onSuccess,
    onFailure,
  })
}

export const forgetDevice = async (user, onSuccess, onFailure) => {
  await user.setDeviceStatusNotRemembered(onSuccess, onFailure)
}

export const validateUserInput = (input) => {
  let errors = []
  if (!input || !input.given_name)
    errors.push('First name cannot be empty')
  else if (!checkForOnlyLetters(!input || input.given_name))
    errors.push('First name can only contain letters')

  if (!input || !input.family_name)
    errors.push('Last name cannot be empty')
  else if (!input || !checkForOnlyLetters(input.family_name))
    errors.push('Last name can only contain letters')

  if (!input || !input.zip)
    errors.push('Zip code of property address cannot be empty')
  if (!input || !input.last4ssn)
    errors.push('Social security number cannot be empty')
  else if (!input || isNaN(input.last4ssn))
    errors.push('Social security must be a number')
  else if (!input || input.last4ssn.length !== 4)
    errors.push(
      'Last 4 of social security number must contain 4 numbers',
    )

  if (!input || !input.loan_number)
    errors.push('Loan number cannot be empty')
  else if (!input || isNaN(input.loan_number))
    errors.push('Loan number must be a number')

  return errors
}

export const validateUserNameAndPasswords = (
  username,
  confirmUsername,
  password,
  confirmPassword,
  syncErrorCallback,
) => {
  if (!checkValidEmail(username)) {
    syncErrorCallback('Email is not a valid email address')
    return false
  }

  if (!checkValidEmail(confirmUsername)) {
    syncErrorCallback('Confirm Email is not a valid email address')
    return false
  }

  if (username !== confirmUsername) {
    syncErrorCallback('The values you entered for email do not match. Please enter matching values for Email and Confirm Email fields.')
    return false
  }
  if (password !== confirmPassword) {
    syncErrorCallback('Passwords do not match.')
    return false
  }
  if (password.length < 8 || password.length > 33) {
    syncErrorCallback(
      'Password must be between 8 and 32 characters long',
    )
    return false
  }
  return true
}

const checkForOnlyLetters = (input) =>
  !!/^[a-zA-Z .,'-]*$/g.test(input)
/*eslint-disable */
const checkValidEmail = (input) =>
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
    input,
  )
/*eslint-enable */

export const normalizeErrorMessage = ({ code, message }) => {
  console.log('code', code)
  if (!code) return message
  switch (code.toLowerCase()) {
    case 'invalidparameterexception':
      return 'The password or email you entered is invalid'
    case 'usernameexistsexception':
      return 'This email address is already in use. Please enter a different one'
    case 'codedeliveryfailureexception':
      return 'An unexpected error has occured'
    default:
      return 'We were not able to find the account information that you have provided. Please double check your entries.'
  }
}

export const validateUpbUser = async({session_id,upb_amount})=>{
  try {
    const response = await axios.post(
       awsConfig.API.verificationUpbUrl,
      {
        session_id,
        upb_amount,
      },
      {
        headers: {
          'X-API-KEY': awsConfig.API.verificationApiKey,
          'Content-Type': 'application/json',
        },
      },
    )
    const result = response.data
    console.log('response from verification API', response)
    return result
  } catch (error) {
    throw new Error('Your entry does not match our records. Please check your entry and try again.')
  }
}

export const validateUserAccountValidation = async ({
  given_name,
  family_name,
  zip,
  last4ssn,
  loan_number,
}) => {
  const response = await axios.post(
    awsConfig.API.accountValidationApiUrl,
    {
      given_name,
      family_name,
      last4ssn,
      loan_number,
      zip,
    },
    {
      headers: {
        'X-API-KEY': awsConfig.API.verificationApiKey,
        'Content-Type': 'application/json',
      },
    },
  )
  const result = response.data
  console.log('response from account validation API', response)

  if (result.celinkErrorMsg) throw new Error(result.celinkErrorMsg)

  if (!result.personId)
    throw new Error(
      'We were not able to find the account information that you have provided. Please double check your entries.',
    )

  if (result.subId)
    throw new Error(
      'The account for this borrower already exists. Please use the "Forgot your password" link on the sign in screen',
    )

  return result
}

export const validateUser = async ({
  given_name,
  family_name,
  zip,
  last4ssn,
  loan_number,
}) => {
  try {
    const response = await axios.post(
      awsConfig.API.verificationApiUrl,
      {
        given_name,
        family_name,
        last4ssn,
        loan_number,
        zip,
      },
      {
        headers: {
          'X-API-KEY': awsConfig.API.verificationApiKey,
          'Content-Type': 'application/json',
        },
      },
    )
    const result = response.data
    console.log('response from verification API', response)
    if (!result.person_id)
      throw new Error(
        'We were not able to find the account information that you have provided. Please double check your entries.',
      )
    return result
  } catch (error) {
    let errMessage = 'We were not able to find the account information that you have provided. Please double check your entries.'
    if (error.response && error.response.status==400){
      if (error.response.data && error.response.data.message?.includes('borrower already exists')){
        errMessage = 'The account for this borrower already exists. Please use the "Forgot your password" link on the sign in screen.'
      }
    }
    throw new Error(errMessage)
  }
}

export const getPasswordValidationList = (
  password,
  confirmPassword,
  showConfirmPassword,
) => {
  const list = [
    { regex: /^.{12,32}$/, message: 'Between 12 and 32 characters' },
    { regex: /.*[0-9].*/, message: 'A number' },
    { regex: /.*[A-Z].*/, message: 'An uppercase letter' },
    {
      regex: /[(!@#$%^&*)]/,
      message: 'A special character (!@#$%^&*)',
    },
    { regex: /.*[a-z].*/, message: 'A lowercase letter' },
  ].map(({ regex, message }) => {
    return {
      success: regex.test(password),
      message,
    }
  })

  if (showConfirmPassword)
    list.push({
      success: confirmPassword.length && password === confirmPassword,
      message: 'Password and confirm password match',
    })

  return list
}
