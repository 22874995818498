import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import Styled from 'styled-components'

import AuthContainer from './AuthContainer'
import Title from '../../Common/Title'
import { getRandomLetters } from '../../services/auth'
import PasswordInput from '../auth/ShowHidePassword'
import Button from '../../Common/button'
import { buildTestID } from '../../Utils/utils'

const StyledSignIn = Styled.div`
  font-size: 20px;

  .error-message {
    color: ${({ theme }) => theme.colors.error};
  }

  .sign-in-button {
    display:flex;
    justify-content: flex-end;
  }

  .reset-password-button {
    display:flex;
    justify-content: flex-start;
  }
`

const SignIn = ({ setAuthState, setAuthData, setUserInfo, setIsAuthenticated }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [randomLetters, setRandomLetters] = useState('')

  const setErrorForAda = (error) => {
    setRandomLetters(getRandomLetters(2))
    setError(error)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      if (!username || !password)
        throw new Error(
          'Please make sure to enter both an email and password.',
        )
      // It will be handled by Login Autho HTMl page Template .....
      const user = await Auth.signIn(
        username.toLowerCase().trim(),
        password,
      )

      const { challengeName } = user

      if (!challengeName) {
        setAuthState('signedIn')
        setAuthData({ user, redirect: true })
        setUserInfo(user)
        setIsAuthenticated(!!user)
      }

      switch (challengeName) {
        case 'NEW_PASSWORD_REQUIRED':
          setAuthState('requireNewPassword', user)
          break

        case 'SMS_MFA':
          setAuthState('confirmSignIn')
          setAuthData(user)
          break

        default:
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        setAuthState('confirmSignUp', { username })
        setAuthData({ username })
      }
      else setErrorForAda('Incorrect username or password')
      return
    }
  }

  return (
    <>
      <Title>Sign In</Title>
      <AuthContainer header="Sign in to your account">
        <StyledSignIn>
          <form onSubmit={handleSubmit}>
            <div className="Section__sectionBody___ihqqd">
              <div className="Form__formField___38Ikl">
                <div className="Input__inputLabel___3VF0S">
                  <label htmlFor="email">
                    <strong>Email *</strong>
                  </label>
                </div>
                <input
                  placeholder="Enter your email"
                  name="email"
                  className="Input__input___3e_bf"
                  onChange={(e) => setUsername(e.target.value)}
                  id="email"
                  aria-required="true"
                  data-testid={buildTestID(
                    'Enter your email',
                    '/home',
                    'sign_in',
                  )}
                />
              </div>
              <div className="Form__formField___38Ikl">
                <div className="Input__inputLabel___3VF0S">
                  <label htmlFor="password">
                    <strong>Password *</strong>
                  </label>
                </div>
                <PasswordInput
                  placeholder="Enter your password"
                  name="password"
                  className="Input__input___3e_bf"
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  aria-required="true"
                  autoComplete="off"
                  datatestid="password_signin"
                />
                <p role="alert">
                  <span
                    data-testid="error-message"
                    className="error-message"
                  >
                    {error}
                    <span
                      style={{ visibility: 'hidden' }}
                      dangerouslySetInnerHTML={{
                        __html: randomLetters,
                      }}
                    ></span>
                  </span>
                </p>
                <div className="reset-password-button">
                  <Button
                    tabIndex="0"
                    onClick={(e) => setAuthState('forgotPassword')}
                    textButton
                    text="Forgot your password? Reset password"
                    small
                    UID="sign_in"
                  />
                </div>
              </div>
            </div>
            <div className="sign-in-button">
              <Button text="Sign In" type="submit" UID="sign_in" />
            </div>
          </form>
        </StyledSignIn>
      </AuthContainer>
    </>
  )
}

export default SignIn
