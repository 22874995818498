import React, {
  useState,
  useLayoutEffect,
  useEffect,
  Suspense,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { storeCognitoId } from '../services/api'
import useLogoutSubscription from '../Hooks/useLogoutSubscription'
import SignIn from '../Components/auth/SignIn'
import OnStateChangeContext from '../Contexts/OnStateChangeContext'
import AuthLayout, {
  CONFIRM_SIGN_IN,
  CONFIRM_SIGN_UP,
} from '../Components/AuthLayout'
import LoadingPage from '../Components/LoadingPage'
import {
  analyticsReset,
  trackRegistration,
} from '../Global/Analytics'
import { removeAllApiCacheEntries } from '../services/api'
import { getCookie, setCookie } from '../Utils/utils'
import styled from 'styled-components'
import { clearLocalCache } from '../services/cache'
import { useApolloClient } from '@apollo/client'
const SignUp = React.lazy(() => import('../Components/auth/SignUp'))
const ConfirmSignIn = React.lazy(() =>
  import('../Components/auth/ConfirmSignIn'),
)
const ConfirmSignUp = React.lazy(() =>
  import('../Components/auth/ConfirmSignUp'),
)
const ConfirmPhone = React.lazy(() =>
  import('../Components/auth/ConfirmPhone'),
)
const AddPhone = React.lazy(() =>
  import('../Components/auth/AddPhone'),
)
const ForgotPassword = React.lazy(() =>
  import('../Components/auth/ForgotPassword'),
)
const SignUpLayout = React.lazy(() =>
  import('../Components/SignUpLayout'),
)
const MyLoanContainer = React.lazy(() =>
  import('../Containers/MyLoanContainer'),
)
const FinishYourAccountContainer = React.lazy(() =>
  import('../Containers/FinishYourAccountContainer'),
)
const BadRequestPage = React.lazy(() =>
  import('../Components/BadRequestPage'),
)

const AlreadyRegistredAccount = styled.h2`
  text-align: center;
  padding: 50px;
  background: white;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  border-radius: 50px;
`

const AuthRouter = ({
  children,
  isAuthenticated,
  setUserInfo,
  setIsAuthenticated,
  authUser,
}) => {
  const location = useLocation()
  const [authState, setAuthState] = useState(
    isAuthenticated
      ? 'signedIn'
      : location.pathname.includes('register')
      ? 'signUp'
      : 'signIn',
  )
  const [authData, setAuthData] = useState({
    user: authUser,
    redirect: authUser ? true : false,
  })
  const [isAuthorized, setIsAuthorized] = useState(false)
  const [skip, setSkip] = useState(false)
  let history = useHistory()
  const client = useApolloClient()
  useEffect(() => {
    setAuthData({ user: authUser, redirect: true })
  }, [authUser])

  const { pathname } = useLocation()

  const onValidRegisterPath =
    pathname.includes('paperless') || pathname.includes('register')

  useEffect(() => {
    if (!isAuthenticated) {
      // navigates back to home page at end of signIn flow
      if (onValidRegisterPath && authState === 'signIn') {
        history.push('/')
        // changes auth state to match /register endpoint
      } else if (
        onValidRegisterPath &&
        authState !== 'confirmSignUp'
      ) {
        setAuthState('signUp')
      }

      if (onValidRegisterPath && authState === 'signUp') {
        trackRegistration.Started()
      }
    }
  }, [
    authState,
    authData,
    onValidRegisterPath,
    history,
    isAuthenticated,
  ])

  return (
    <OnStateChangeContext.Provider value={authState}>
      {getPage(
        authState,
        setAuthState,
        authData,
        setAuthData,
        children,
        // onStateChange,
        isAuthorized,
        setIsAuthorized,
        history,
        setUserInfo,
        setIsAuthenticated,
        authUser,
        setSkip,
        skip,
        pathname,
        client,
      )}
    </OnStateChangeContext.Provider>
  )
}

const getPage = (
  authState,
  setAuthState,
  authData,
  setAuthData,
  children,
  // onStateChange,
  isAuthorized,
  setIsAuthorized,
  history,
  setUserInfo,
  setIsAuthenticated,
  authUser,
  setSkip,
  skip,
  pathname,
  client,
) => {
  switch (authState) {
    case 'setup':
    case 'signIn': {
      return authUser ? (
        <LoadingPage />
      ) : (
        <AuthLayout
          setAuthState={setAuthState}
          setAuthData={setAuthData}
          setUserInfo={setUserInfo}
          setIsAuthenticated={setIsAuthenticated}
          authState={authState}
        />
      )
    }

    case 'signUp': {
      return (
        <Suspense fallback={<LoadingPage />}>
          {pathname !== '/register/failed' ? (
            <SignUpLayout>
              <SignUp
                setAuthState={setAuthState}
                setAuthData={setAuthData}
              />
            </SignUpLayout>
          ) : (
            <BadRequestPage error={'forbiddenEmail'} />
          )}
        </Suspense>
      )
    }

    case 'confirmSignIn': {
      return (
        <AuthLayout
          authData={authData}
          setAuthState={setAuthState}
          setAuthData={setAuthData}
          setUserInfo={setUserInfo}
          setIsAuthenticated={setIsAuthenticated}
          activeTab={CONFIRM_SIGN_IN}
        />
      )
    }

    case 'confirmSignUp':
      return (
        <AuthLayout
          authData={authData}
          setAuthState={setAuthState}
          setAuthData={setAuthData}
          setUserInfo={setUserInfo}
          setIsAuthenticated={setIsAuthenticated}
          activeTab={CONFIRM_SIGN_UP}
        >
          <Suspense fallback={<LoadingPage />}>
            <ConfirmSignUp
              setAuthState={setAuthState}
              authData={authData}
            />
          </Suspense>
        </AuthLayout>
      )

    case 'confirmPhoneAndMFA': {
      return (
        <Suspense fallback={<LoadingPage />}>
          <FinishYourAccountContainer
            setAuthState={setAuthState}
            setSkip={setSkip}
          />
        </Suspense>
      )
    }

    case 'confirmPhone': {
      return (
        <AuthLayout>
          <Suspense fallback={<LoadingPage />}>
            <ConfirmPhone
              authData={authData}
              setAuthState={setAuthState}
            />
          </Suspense>
        </AuthLayout>
      )
    }

    case 'addPhone': {
      return (
        <AuthLayout>
          <Suspense fallback={<LoadingPage />}>
            <AddPhone
              authData={authData}
              setAuthState={setAuthState}
            />
          </Suspense>
        </AuthLayout>
      )
    }

    case 'forgotPassword': {
      return <AuthLayout />
    }

    case 'publicPage': {
      return <>{authData}</>
    }

    case 'signedIn': {
      if (!authData.user)
        handleSignedIn(
          setAuthState,
          setIsAuthorized,
          history,
          skip,
          authUser,
          client,
        )

      const views = [
        'esign',
        'finish-your-account',
        'account-settings',
        'upload',
        'payoff-quote',
        'advance-request',
        'enable-multifactor',
        'register',
      ]

      if (
        views.some((view) =>
          history.location.pathname.includes(view),
        ) &&
        authData
      )
        authData.redirect = false

      if (authData?.redirect) {
        authData.redirect = false
        // Need to change this value before deployment
        if (new Date().getDate() <= 9) {
          history.push('/finish-your-account')
          return (
            <Suspense fallback={<LoadingPage />}>
              <FinishYourAccountContainer />
            </Suspense>
          )
        }
        history.push('/my-loan')
        return (
          <Suspense fallback={<LoadingPage />}>
            <MyLoanContainer />
          </Suspense>
        )
      }
      if (pathname.endsWith('/register')) {
        return (
          <Suspense fallback={<LoadingPage />}>
            <AlreadyRegistredAccount>
              You are already logged in to a registered account
            </AlreadyRegistredAccount>
          </Suspense>
        )
      }

      return authData ? <>{children}</> : <LoadingPage />
    }

    case 'loading':
    default: {
      return <React.Fragment />
    }
  }
}

const handleSignedIn = async (
  setAuthState,
  setIsAuthorized,
  history,
  skip,
  authUser,
  client,
) => {
  const confirmSignUpCookie = getCookie('confirm_sign_up')
  const storeCognitoIdCookie = getCookie('store_cognito_id')

  if (!storeCognitoIdCookie) {
    setCookie('store_cognito_id', 'true', 1)
    storeCognitoId(
      authUser['custom:person_id'],
      authUser['cognito:identityId'],
      client,
    )
  }

  if (!authUser['email_verified']) {
    setAuthState('confirmSignUp')
    return
  }

  if (
    !authUser['phone_number_verified'] &&
    !authUser['custom:phone_verif_opt_out'] &&
    !skip &&
    confirmSignUpCookie === 'true'
  ) {
    setCookie('confirm_sign_up', 'false', -1)
    history.push('/finish-your-account')
    setAuthState('confirmPhoneAndMFA')
    return
  }

  setIsAuthorized(true)
}

export default AuthRouter
