import { getItemOrUpdate, removeEntry } from './cache'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import advanceFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'

import {
  getTransactions as getTransactionGraphQl,
  getLoan as getLoanGraphQl,
  saveCognitoId,
  requestCode,
  confirmCode,
  putLocRequest,
  getLocStatus,
  getLocEligibility,
  getPayOffEligibility,
  logoutSubscription,
  getPayoffQuote,
  putPayoffRequest,
  putStatementPreference,
  postESignature,
  getAgreementSignature,
  updateAgreementSign,
  getAnnualPrivacyNotice,
  postIndividualContact,
  postIndividualHomePhoneNumber,
  postIndividualMobilePhoneNumber,
  postIndividualWorkPhoneNumber,
  getValidateIdentity,
  getStatements,
  downloadStatement,
  FORMS_UPLOAD,
} from '../Queries'

export const GET_USER_KEY = 'GET_USER_KEY'
export const GET_TRANSACTIONS_KEY = 'GET_TRANSACTIONS_KEY'
export const GET_LOAN_KEY = 'GET_LOAN_KEY'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanceFormat)

export const getTransactions = async (
  client,
  personId,
  refresh = false,
) => {
  // return await getItemOrUpdate(
  //   GET_TRANSACTIONS_KEY,
  //   () =>
  //     API.graphql(graphqlOperation(getTransactionGraphQl(personId))),
  //   refresh,
  // )
  return await getItemOrUpdate(
    GET_TRANSACTIONS_KEY,
    async () => {
      return await client.query({
        query: getTransactionGraphQl(personId), // Call function to generate query dynamically
        fetchPolicy: 'no-cache',
      })
    },
    refresh,
  )
}

export const getLoan = async (client, personId, refresh = false) => {
  // return await getItemOrUpdate(
  //   GET_LOAN_KEY,
  //   () => API.graphql(graphqlOperation(getLoanGraphQl(personId))),
  //   refresh,
  // )
  return await getItemOrUpdate(
    GET_LOAN_KEY,
    async () => {
      return await client.query({
        query: getLoanGraphQl(personId), // Call function to generate query dynamically
        fetchPolicy: 'no-cache',
      })
    },
    refresh,
  )
}

export const storeCognitoId = async (personId, cognitoId, client) => {
  const gql = saveCognitoId(personId, cognitoId)
  // const response = await API.graphql(graphqlOperation(gql))
  const response = await client.query({
    query: gql,
    fetchPolicy: 'no-cache',
  })
  return response
}

export const runAllApiMethodsToFillCache = async () => {
  console.log('filling api cache')
  getTransactions()
  getLoan()
}

// Remove this functionality
// export const removeAllApiCacheEntries = () => {
//   getLocalStorageKeysContaining(GET_LOAN_KEY).forEach((key) =>
//     removeEntry(key),
//   )
//   getLocalStorageKeysContaining(GET_TRANSACTIONS_KEY).forEach((key) =>
//     removeEntry(key),
//   )
//   getLocalStorageKeysContaining(GET_USER_KEY).forEach((key) =>
//     removeEntry(key),
//   )
// }

export const requestAuthCode = async (sendType, client) =>
  await client.query({
    query: requestCode(sendType),
    fetchPolicy: 'no-cache',
  })
// await API.graphql(graphqlOperation(requestCode(sendType)))

export const confirmAuthCode = async (code, client) =>
  await client.query({
    query: confirmCode(code),
    fetchPolicy: 'no-cache',
  })
// await API.graphql(graphqlOperation(confirmCode(code)))

export const submitLoc = async (
  loanNumber,
  requestDateTime,
  submissionType,
  drawAmount,
  drawSigner,
  payType,
  client,
) =>
  // await API.graphql(
  //   graphqlOperation(
  //     putLocRequest(
  //       loanNumber,
  //       requestDateTime,
  //       submissionType,
  //       drawAmount,
  //       drawSigner,
  //       payType,
  //     ),
  //   ),
  // )
  await client.query({
    query: putLocRequest(
      loanNumber,
      requestDateTime,
      submissionType,
      drawAmount,
      drawSigner,
      payType,
    ),
    fetchPolicy: 'no-cache',
  })

export const retrieveLocStatus = async (loanNumber, client) =>
  await client.query({
    query: getLocStatus(loanNumber),
    fetchPolicy: 'no-cache',
  })
// await API.graphql(graphqlOperation(getLocStatus(loanNumber)))

export const retrieveLocEligibility = async (loanNumber, client) =>
  await client.query({
    query: getLocEligibility(loanNumber),
    fetchPolicy: 'no-cache',
  })
// await API.graphql(graphqlOperation(getLocEligibility(loanNumber)))

export const retrievePayOffEligibility = async (loanNumber, client) =>
  await client.query({
    query: getPayOffEligibility(loanNumber),
    fetchPolicy: 'no-cache',
  })
// await API.graphql(
//   graphqlOperation(getPayOffEligibility(loanNumber)),
// )

export const getPayOffQuote = async (
  loanNumber,
  payoffDate,
  client,
) => {
  return await client.query({
    query: getPayoffQuote(loanNumber, payoffDate),
    fetchPolicy: 'no-cache',
  })
  // return await API.graphql(
  //   graphqlOperation(getPayoffQuote(loanNumber, payoffDate)),
  // )
}

export const retrieveAnnualPrivacyNotice = async (
  client,
  loanNumber,
) => {
  return await client.query({
    query: getAnnualPrivacyNotice(loanNumber),
    fetchPolicy: 'no-cache',
  })
  // return await API.graphql(
  //   graphqlOperation(getAnnualPrivacyNotice(loanNumber)),
  // )
}

const getLocalStorageKeysContaining = (prefix) => {
  if (!prefix) return null
  return Object.keys(localStorage).filter(
    (key) => key.toLowerCase() === prefix.toLowerCase(),
  )
}

export const updateStatementPreference = async (
  statementPreference,
  client,
) =>
  await client.query({
    query: putStatementPreference(statementPreference),
    fetchPolicy: 'no-cache',
  })
// await API.graphql(
//   graphqlOperation(putStatementPreference(statementPreference)),
// )

export const submitESignAgreement = async (
  borrower,
  optInTimestamp,
  consentTimestamp,
  thirdPartyTimestamp,
  addressTimestamp,
  dob,
  lastFourSsn,
  thirdParty,
  nbsInformation,
  client,
) =>
  await client.query({
    query: postESignature,
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        loanNumber: borrower.loanNumber,
        personId: borrower.personId,
        signatureData: {
          birthDate: dob,
          lastFourTaxPayerId: lastFourSsn,
          loginDetails: borrower.email,
          firstName: borrower.firstName,
          lastName: borrower.lastName,
          signatureText: {
            birthDate: dob,
            lastFourTaxPayerId: lastFourSsn,
            addressValidation: addressTimestamp,
          },
          createdBy: borrower.email,
        },
        pdfGenerate: {
          address: borrower.houseNumberStreeName,
          city: borrower.city,
          postalCode: borrower.postalCode,
          state: borrower.state,
          signatureDateTime: dayjs()
            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
            .format('MM/DD/YYYY hh:mm A z'),
        },
        thirdParty: thirdParty,
        nonBorrowingSpouse: nbsInformation,
        agreements: [
          {
            descriptionId: 1,
            signatureDateTime: optInTimestamp,
          },
          {
            descriptionId: 2,
            signatureDateTime: consentTimestamp,
          },
          {
            descriptionId: 3,
            signatureDateTime: dayjs.utc().local().format(),
          },
        ],
      },
    },
  })
// await API.graphql(
//   graphqlOperation(
//     postESignature,
//     {
//       "input": {
//         "loanNumber": borrower.loanNumber,
//         "personId": borrower.personId,
//         "signatureData": {
//           "birthDate": dob,
//           "lastFourTaxPayerId": lastFourSsn,
//           "loginDetails": borrower.email,
//           "firstName": borrower.firstName,
//           "lastName": borrower.lastName,
//           "signatureText": {
//             "birthDate": dob,
//             "lastFourTaxPayerId": lastFourSsn,
//             "addressValidation": addressTimestamp
//           },
//           "createdBy": borrower.email
//         },
//         "pdfGenerate": {
//           "address": borrower.houseNumberStreeName,
//           "city": borrower.city,
//           "postalCode": borrower.postalCode,
//           "state": borrower.state,
//           "signatureDateTime": dayjs().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY hh:mm A z')
//         },
//         "thirdParty": thirdParty,
//         "nonBorrowingSpouse": nbsInformation,
//         "agreements": [
//           {
//             "descriptionId": 1,
//             "signatureDateTime": optInTimestamp
//           },
//           {
//             "descriptionId": 2,
//             "signatureDateTime": consentTimestamp
//           },
//           {
//             "descriptionId": 3,
//             "signatureDateTime": dayjs.utc().local().format()
//           }
//         ]
//       }
//     }
//   )
// )

export const getESignAgreementConsent = async (client, personId) => {
  return await client.query({
    query: getAgreementSignature,
    fetchPolicy: 'no-cache',
    variables: {
      descriptionId: 2,
      personId: personId,
    },
  })
  // return await API.graphql(
  //   graphqlOperation(
  //     getAgreementSignature,
  //     {
  //       "descriptionId": 2,
  //       "personId": personId
  //     }))
}

// export const getESignAgreementConsent1 = async (client,personId) => {
//   return await client.query(
//       {
//         query:getAgreementSignature,
//         fetchPolicy: 'no-cache',
//         variables: {
//             "descriptionId": 2,
//             "personId": personId
//         }
//       }
//       )
// }

export const updatePayoffRequest = async (
  destination,
  requestedGoodThroughDate,
  type,
  client,
) => {
  return await client.query({
    query: putPayoffRequest,
    fetchPolicy: 'no-cache',
    variables: {
      destination,
      requestedGoodThroughDate,
      type,
    },
  })
  // return await API.graphql(
  //   graphqlOperation(
  //     putPayoffRequest,
  //     {
  //       destination,
  //       requestedGoodThroughDate,
  //       type
  //     }
  // ))
}

export const postESignAgreementConsent = async (
  signatureId,
  email,
  client,
) => {
  return await client.query({
    query: updateAgreementSign(signatureId, email),
    fetchPolicy: 'no-cache',
  })

  // return await API.graphql(
  //   graphqlOperation(
  //     updateAgreementSign(signatureId, email)))
}

export const updateIndividualContact = async (input, client) => {
  await client.query({
    query: postIndividualContact(
      input.cellConsent,
      input.mobilePhoneNumber,
    ),
    fetchPolicy: 'no-cache',
  })
  // await API.graphql(
  //   graphqlOperation(
  //     postIndividualContact(input.cellConsent, input.mobilePhoneNumber)
  //   ))
}

export const updateIndividualPhoneNumber = async (input, client) => {
  if (input.homePhoneNumber) {
    await client.query({
      query: postIndividualHomePhoneNumber(
        input.cellConsent,
        input.homePhoneNumber,
      ),
      fetchPolicy: 'no-cache',
    })
    // await API.graphql(
    //   graphqlOperation(
    //     postIndividualHomePhoneNumber(input.cellConsent, input.homePhoneNumber)
    //   ))
  }

  if (input.mobilePhoneNumber) {
    await client.query({
      query: postIndividualMobilePhoneNumber(
        input.cellConsent,
        input.mobilePhoneNumber,
      ),
      fetchPolicy: 'no-cache',
    })
    // await API.graphql(
    //   graphqlOperation(
    //     postIndividualMobilePhoneNumber(input.cellConsent, input.mobilePhoneNumber)
    //   ))
  }

  if (input.workPhoneNumber) {
    await client.query({
      query: postIndividualWorkPhoneNumber(
        input.cellConsent,
        input.workPhoneNumber,
      ),
      fetchPolicy: 'no-cache',
    })
    // await API.graphql(
    //   graphqlOperation(
    //     postIndividualWorkPhoneNumber(input.cellConsent, input.workPhoneNumber)
    //   ))
  }
}

export const checkValidateIdentity = async (
  loanNumber,
  ssn,
  dateOfBirth,
  zipCode,
  client,
) => {
  return await client.query({
    query: getValidateIdentity(loanNumber, ssn, dateOfBirth, zipCode),
    fetchPolicy: 'no-cache',
  })
  // return await API.graphql(
  //   graphqlOperation(
  //     getValidateIdentity(loanNumber, ssn, dateOfBirth, zipCode)))
}

export const getStatementsList = async (client, loanNumber, lang) => {
  return await client.query({
    query: getStatements(loanNumber, lang),
    fetchPolicy: 'no-cache',
  })
}

export const getStatementsDownload = async (
  client,
  lang,
  statement,
) => {
  return await client.query({
    query: downloadStatement(lang, statement),
    fetchPolicy: 'no-cache',
  })
}

export const uploadFiles = async (client, formContent,fileName, formType) => {
  return await await client.mutate({
    mutation: FORMS_UPLOAD,
    variables: { formContent, formType, fileName },
  });
}
