import { GET_LOAN_KEY, GET_TRANSACTIONS_KEY } from './api'

export const getItemOrUpdate = async (
  key,
  updaterIfNotInCache,
  refresh = false,
) => {
  let item = getItem(key)
  if (refresh || !item) {
    item = await updaterIfNotInCache()
    setItem(key, item)
    if (key.includes('GET_LOAN_KEY'))
      sessionStorage.setItem('loanId', JSON.stringify(item.data.loan.loanData.loanId))
  }

  return item
}

export const getItem = (key) => {
  const item = localStorage.getItem(key);
  return item && item!=="undefined" ? JSON.parse(item) : null;
};

export const setItem = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const clearLocalCache = () => {
  localStorage.removeItem(GET_LOAN_KEY);
  localStorage.removeItem(GET_TRANSACTIONS_KEY);
  localStorage.removeItem('ajs_user_traits');
};

export const removeEntry = (key) => {
  localStorage.removeItem(key);
};
