import {
    ApolloClient,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
    createHttpLink,
  } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { createContext } from "react";
import { setContext } from "@apollo/client/link/context";
import LoadingPage from "./Components/LoadingPage";
   
  export const ClientProvider = createContext();
   
  export default function ApolloWrapper({children}){
    const {isAuthenticated,isLoading} = useAuth0()
    if (isLoading){
      return <LoadingPage />
    }
    return (
      isAuthenticated?
        <AuthApollo>
          {children}
        </AuthApollo>:
        <>
        <AuthApollo>
        {children}
        </AuthApollo>
        </>
      
    )
  }
  export function AuthApollo({ children }) {
    const {
      getAccessTokenSilently,
      getIdTokenClaims
    } = useAuth0();
   
    const httpLink1 = createHttpLink({
  uri: "https://ouvkxr5nrbfphakw43xhcr64am.appsync-api.us-east-1.amazonaws.com/graphql",
    });
    const authLink = setContext(async (_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = await getAccessTokenSilently();
      const idClaims = await getIdTokenClaims()
      const idToken = idClaims.__raw
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          Authorization:idToken
        },
      };
    });
   
    // const httpLink = new HttpLink({
    //   uri: "https://ouvkxr5nrbfphakw43xhcr64am.appsync-api.us-east-1.amazonaws.com/graphql",
    //       headers: {
    //         Authorization:
    //           "eyJraWQiOiJvZEdpdDZvMDVKcmpjNUpDN01zalI1anRQYlk0MTVycU43SnlzaExNRnI4PSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJjNThkNTkwYy1lNTBhLTQ4MDQtODM0Mi04NTllZjRiZDIxMDciLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfb1lOWlhwU0VNIiwiY3VzdG9tOnBlcnNvbl9pZCI6IjE3NzMzNSIsImNvZ25pdG86dXNlcm5hbWUiOiJjNThkNTkwYy1lNTBhLTQ4MDQtODM0Mi04NTllZjRiZDIxMDciLCJnaXZlbl9uYW1lIjoiSk9BTiIsImF1ZCI6IjRjZnB2YWlvbTFhMzU4M21qbzlqbjM4MnExIiwiZXZlbnRfaWQiOiIzZGFjOTczYS1hMGVjLTQwMTQtOWU5MC0zMTM2NGQ1Mzk3MjciLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTczOTk3NDM2NSwiZXhwIjoxNzM5OTc1MzI1LCJpYXQiOjE3Mzk5NzQzNjUsImZhbWlseV9uYW1lIjoiQmRpaWFqaWJnIiwiZW1haWwiOiJ2YW1zaWNlbGluK2RldjEtNDk1NjJAZ21haWwuY29tIn0.RGGqWdiLTSsyaMRV3XtVciMjI0bqJH2DYADCZYztu36odjRMWgHmyDZ9R1jKp29b6U3x2M-NQYyKyG9xLjjA5ig5q59XzFHdxpCF_97wN6V6MOgYVe8AgWR0Bx17RCRuKrGWLDazV_p1aFX3lglrReiN06MJQVJrZwVAm1QLlO1XVdKlgeEhpqLyOnHk-DDXybQDgNbAaAjMq_w_C-cg0JIgxft9AeaygGmwJ_DlLjCnltaQ4cD2y_xyC1KmzvpzOR6tiusBeRtV-PoNW1z9ed6u6T4m_PY5G3a3JyhJWSmGncpahrV2WDwROt5aCmL85_B3XBnFUfFdv-anBON66g"
    //       },
    // });
   
    const client = new ApolloClient({
      // link: httpLink,
      link: authLink.concat(httpLink1),
      cache: new InMemoryCache(),
    });
    return (
      <ClientProvider.Provider value={client}>
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </ClientProvider.Provider>
    );
  }