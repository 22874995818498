import { useState, useEffect } from 'react'

import { retrievePayOffEligibility, getLoan } from '../services/api'

import { extractLoan } from '../Utils/apiExtractors'
import { useApolloClient } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'

export const useRetrievePayOffEligibility = () => {
  const [payOffEligibility, setPayOffEligibility] = useState({
    loading: true,
  })
  const client = useApolloClient()
  const {user:authUser} = useAuth0()
  useEffect(()=>{
    const intervalId = setInterval(async ()=>{
      const user = localStorage.getItem('ajs_user_traits');
      if(!user){
        clearInterval(intervalId);
        return
      };

      const personId = authUser['custom:person_id']
      const cachedLoan = localStorage.getItem('GET_LOAN_KEY')
      if (cachedLoan && cachedLoan !== 'undefined') {
        clearInterval(intervalId);
        const loan = JSON.parse(cachedLoan).data.loan.loanData
        let payoffData = null
        let error = null
        try {
          const loanNumber = loan.loanId
          const state = loan.property.state

          payoffData = await retrievePayOffEligibility(loanNumber,client)

          if (state === 'OR' || state === 'NV') {
            payoffData.data.getPayoffEligibility.info.eligible = false
          }
        } catch (err) {
          console.log('err', err)
          error = err
        } finally {
          setPayOffEligibility({
            data: payoffData && payoffData.data.getPayoffEligibility.info || null,
            error,
            loading: false,
          })
        }
      }

    },500)

    return ()=>{
      clearInterval(intervalId);
    }
  },[])

  return payOffEligibility
}