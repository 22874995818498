import { createContext } from "react";

export const MyLoanContext = createContext({
    eligibilityData: null,
    payoffData:null, 
    eligibilityError:null, 
    eligibilityLoading:false,
    payoffError: null,
    payoffLoading: false
})
